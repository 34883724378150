(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerJS', [
			'filemakerJSConfig',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'manageSettings',
			'utilities',
			'$sce',
			'manageSchedules',
			'manageFetch',
			filemakerJS,
		]);

	function filemakerJS(
		filemakerJSConfig,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		manageSettings,
		utilities,
		$sce,
		manageSchedules,
		manageFetch
	) {
		var eventEditQueue = {};
		var fileConfig;
		var newEvent;
		var currentSchedule;
		var fromButtonAction;

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getEventEditQueue: getEventEditQueue,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getMapEvents: getMapEvents,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			deleteRepeatingInstance: deleteRepeatingInstance,
			showDetail: showDetail,
			showEventOnLayout: showEventOnLayout,
			goToEvent: goToEvent,
			getContacts: getContacts,
			getProjects: getProjects,
			// fileMakerCall:fileMakerCall,
			loadFileMakerJSConfig: loadFileMakerJSConfig,
			repeatingConfig: repeatingConfig,

			// performScript:performScript,
		};

		function repeatingConfig() {
			return false;
		}

		function getConfig() {
			return filemakerJSConfig.config();
		}

		function getFieldMap() {
			return filemakerJSConfig.fieldMap();
		}

		function getUnusedMap() {
			return filemakerJSConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return filemakerJSConfig.allowHTMLMap();
		}
		function getHiddenFieldMap() {
			return filemakerJSConfig.hiddenFieldMap();
		}
		function getReadOnlyFieldMap() {
			return filemakerJSConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function processExternalEdit(data) {
			//process external creation or editing
			var schedule =
				getScheduleFromPayload(data?.payload) || currentSchedule;
			if (data.status === 200) {
				var options;
				var events;
				var id = schedule
					? getIdFromEventArray(data.payload.eventArray, schedule)
					: '';
				var repetitions = data.payload.repetitions;
				var event = schedule
					? calendarIO.getDisplayEvent(id, schedule)
					: false;
				var isNew = false;
				var revertObject = {};
				var input = {};
				input.status = 200;
				input.payload = data.payload.eventArray;
				var isArray = typeof input.payload[0] === 'object';
				var element = seedcodeCalendar.get('element');

				if (!event && newEvent) {
					event = newEvent;
					newEvent = null;
					isNew = true;
				} else if (!event) {
					//process was not started from dayback so we don't have an event or schedule
					//can be an array or a single JSON eventArray
					if (!isArray) {
						input.payload = [input.payload];
					}
					events = input.payload;
				}

				//mutate event and update full calendar
				var result = {};
				var editEvent = event
					? calendarIO.createEditEvent(event, schedule.fieldMap)
					: false;
				if (!data.payload.delete && !events) {
					result = processResult(
						input,
						event,
						null,
						null,
						null,
						null,
						schedule
					);
					event = result.event;
					editEvent = calendarIO.createEditEvent(
						event,
						schedule.fieldMap
					);
				} else if (events) {
					result.modified = true;
				}
				if (data.payload.delete && event) {
					//set options to tell changes function to skip to the callback
					options = {
						runCallback: true,
					};
					event.removed = true;
					calendarIO.deleteEvent(editEvent, deleteCallback, options);
				} else if (
					result.modified ||
					(repetitions && repetitions.length > 0)
				) {
					if (events) {
						var config = seedcodeCalendar.get('config');
						config.suppressEditEventMessages = true;
						var e = 0;
						processEvents(resetMessage);
					} else {
						options = {
							runCallback: true,
							revertObject: createRevertObject(event),
							preventUndo: isNew,
						};

						if (repetitions && repetitions.length > 0) {
							processRepetitons(repetitions, schedule);
						}
						calendarIO.getEventChanges(
							event,
							editEvent,
							null,
							openEvent,
							options
						);
					}
				} else {
					openEvent(event);
				}

				function resetMessage() {
					config.suppressEditEventMessages = false;
				}

				function processEvents(callback) {
					var input = {};
					input.status = 200;
					input.payload = events[e];
					var schedule = schedulesById(
						events[e][events[e].length - 1]
					);
					//mutate to get the id
					event = mutateFileMakerEvents([events[e]], schedule)[0];
					//find our event in the dom
					var clientEvents = element.fullCalendar('clientEvents');
					var clientEvent = false;
					for (var c = 0; c < clientEvents.length; c++) {
						if (clientEvents[c].eventID === event.eventID) {
							clientEvent = clientEvents[c];
						}
					}
					if (clientEvent) {
						//this event exists in the dom/view
						event = clientEvent;
						result = processResult(
							input,
							event,
							null,
							null,
							null,
							null,
							schedule
						);
						event = result.event;
						options = {
							runCallback: true,
							revertObject: createRevertObject(event),
							preventUndo: isNew,
						};
						calendarIO.getEventChanges(
							event,
							null,
							null,
							null,
							options
						);
					} else {
						if (schedule.status && schedule.status.selected) {
							//make sure schedule is active to render
							element.fullCalendar('renderEvent', event);
						}

						options = {
							runCallback: true,
							revertObject: createRevertObject(event),
							preventUndo: isNew,
						};
						calendarIO.getEventChanges(
							event,
							null,
							null,
							null,
							options
						);
					}
					e++;
					if (e < events.length) {
						setTimeout(function () {
							processEvents(callback);
						}, 100);
					} else {
						callback();
					}
				}
				function removeEvents(callback) {
					var input = {};
					input.status = 200;
					input.payload = events[e];
					var schedule = schedulesById(
						events[e][events[e].length - 1]
					);
					//mutate to get the id
					event = mutateFileMakerEvents([events[e]], schedule)[0];
					var editEvent = event
						? calendarIO.createEditEvent(event, schedule.fieldMap)
						: false;
					options = {
						runCallback: true,
					};
					event.removed = true;
					calendarIO.deleteEvent(editEvent, deleteCallback, options);
					e++;
					if (e < events.length) {
						setTimeout(function () {
							removeEvents(callback);
						}, 100);
					} else {
						callback();
					}
				}
			}

			function openEvent(event) {
				if (fromButtonAction) {
					var view = seedcodeCalendar.get('view');
					var viewStart = view.start;
					var viewEnd = view.end;
					var date = event.start.format('YYYY-MM-DD');
					var id = event.eventID;
					var parm;
					if (
						event.start.isSameOrBefore(viewEnd) &&
						event.start.isSameOrAfter(viewStart)
					) {
						//event is still in the current view
						parm = '#/?id=' + id;
					} else {
						//event is still in the a differnt view, reset data focus
						parm = '#/?date=' + date + '&id=' + id;
					}
					location.hash = parm;
				}
			}
			function processRepetitons(repetitons, schedule) {
				var events = mutateFileMakerEvents(repetitons, schedule);
				var end = seedcodeCalendar.get('view').end;
				for (var i = 0; i < events.length; i++) {
					if (events[i].start.isBefore(end)) {
						seedcodeCalendar
							.get('element')
							.fullCalendar('renderEvent', events[i]);
					}
				}
			}
			function deleteCallback() {
				fullCalendarBridge.removeEvents(
					seedcodeCalendar.get('element'),
					event
				);
			}
			function schedulesById(id) {
				var schedules = seedcodeCalendar.get('schedules');
				for (var i = 0; i < schedules.length; i++) {
					if (schedules[i].id === id) {
						return schedules[i];
					}
				}
			}
			function createRevertObject(event) {
				var revertObject = {};
				var fieldMap = fullFieldMap(event.schedule);
				//create our revert object here before applying the change
				revertObject.schedule =
					event.eventStatus && event.eventStatus.revertObject
						? event.eventStatus.revertObject.schedule
						: event.schedule;
				for (var property in fieldMap) {
					//Set revert object data
					if (property === 'start' || property === 'end') {
						revertObject[property] =
							event.eventStatus && event.eventStatus.revertObject
								? event.eventStatus.revertObject[
										property
									].clone()
								: event[property].clone();
					} else if (Array.isArray(event[property])) {
						revertObject[property] =
							event.eventStatus && event.eventStatus.revertObject
								? event.eventStatus.revertObject[
										property
									].slice(0)
								: event[property].slice(0);
					} else {
						revertObject[property] =
							event.eventStatus && event.eventStatus.revertObject
								? event.eventStatus.revertObject[property]
								: event[property];
					}
				}
				//Reset any potential revert objects stored in the event
				if (
					event.eventStatus &&
					event.eventStatus.revertObject &&
					(!options || (options && !options.isCustomAction))
				) {
					event.eventStatus.revertObject = null;
				}
				return revertObject;
			}

			function getScheduleFromPayload(payload) {
				return seedcodeCalendar.get('schedules').find((schedule) => {
					if (payload?.calendarName) {
						return schedule.name === payload.calendarName;
					} else {
						return schedule.tableName === payload?.tableName;
					}
				});
			}
		}

		function fullFieldMap(schedule) {
			//remove unused and append table name to local fields
			var newMap = {};
			var fieldMap = schedule.fieldMap;
			for (var field in fieldMap) {
				if (schedule.unusedMap && !schedule.unusedMap[field]) {
					if (
						fieldMap[field] &&
						fieldMap[field].indexOf('::') === -1
					) {
						//localfield
						newMap[field] =
							schedule.tableName + '::' + fieldMap[field];
					} else {
						//related field
						newMap[field] = fieldMap[field];
					}
				}
			}
			return newMap;
		}

		function removeUnusedFieldMap(schedule) {
			const newMap = {};
			for (let field in schedule.fieldMap) {
				if (!schedule.unusedMap?.[field]) {
					newMap[field] = schedule.fieldMap[field];
				}
			}
			return newMap;
		}

		function buildExpression(schedule) {
			// var fieldMap = fullFieldMap(schedule, true); //schedule.fieldMap;
			// var fieldMap = schedule.fieldMap;
			var fieldMap = removeUnusedFieldMap(schedule);
			var resultArray = [];
			var i = 0;
			for (var field in fieldMap) {
				if (fieldMap[field]) {
					resultArray.push(
						'[' + i + ';' + fieldMap[field] + '; JSONString]'
					);
					i++;
				}
			}
			resultArray.push(
				'[' + i + ';' + '"' + schedule.id + '"' + '; JSONString]'
			);
			var result = resultArray.join(';');
			result = 'JSONSetElement ( "[]" ;' + result + ')';
			return result;
		}

		function mappedFields(schedule) {
			// var fieldMap = schedule.fieldMap;
			var fieldMap = removeUnusedFieldMap(schedule);
			var result = [];
			for (var field in fieldMap) {
				if (fieldMap[field]) {
					result.push(field);
				}
			}
			return result;
		}

		function getIdFromEventArray(eventArray, schedule) {
			// var fieldMap = schedule.fieldMap;
			var fieldMap = removeUnusedFieldMap(schedule);
			var i = 0;
			for (var field in fieldMap) {
				if (field === 'eventID') {
					return eventArray[i];
				}
				if (fieldMap[field]) {
					i++;
				}
			}
			return false;
		}

		function configRequest() {
			//config we're sending to FileMaker file
			var statuses = seedcodeCalendar.get('statuses');
			var resources = seedcodeCalendar.get('resources');
			var config = seedcodeCalendar.get('config');
			var date = seedcodeCalendar.get('date');
			var view = seedcodeCalendar.get('view');
			var user = daybackIO.getUser();
			var statusObject = {};
			var resourceObject = {};
			var i;
			var rgb;

			for (i = 0; i < statuses.length; i++) {
				if (statuses[i].color.substring(0, 1) === '#') {
					rgb = hexToRgb(statuses[i].color);
				} else {
					rgb = statuses[i].color.split('(')[1];
				}

				rgb = rgb.split(',');
				statusObject[statuses[i].name] = {};
				statusObject[statuses[i].name].name = statuses[i].name;
				statusObject[statuses[i].name].folderName =
					statuses[i].folderName || '';
				statusObject[statuses[i].name].r = rgb[0];
				statusObject[statuses[i].name].g = rgb[1];
				statusObject[statuses[i].name].b = rgb[2];
			}
			for (i = 0; i < resources.length; i++) {
				if (resources[i].color.substring(0, 1) === '#') {
					rgb = hexToRgb(resources[i].color);
				} else {
					rgb = resources[i].color.split('(')[1];
				}
				rgb = rgb.split(',');
				resourceObject[resources[i].name] = {};
				resourceObject[resources[i].name].name = resources[i].name;
				resourceObject[resources[i].name].folderName =
					resources[i].folderName || '';
				resourceObject[resources[i].name].shortName =
					resources[i].shortName || '';
			}
			var request = {};
			if (view) {
				request.view = view.name;
				request.start = view.start.format('l');
				request.end = view.end.format('l');
				request.focusDate = date.selected.format('l');
			}
			request.statuses = statusObject;
			request.resources = resourceObject;
			request.account = config.account;
			request.accountName = config.accountName;
			request.userToken = user.auth.token;
			return request;

			//Convert Hex value to RGB
			function hexToRgb(color) {
				var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
					color
				);
				return result
					? 'rgb(' +
							parseInt(result[1], 16) +
							', ' +
							parseInt(result[2], 16) +
							', ' +
							parseInt(result[3], 16) +
							')'
					: null;
			}
		}

		function loadFileMakerJSConfig(callback) {
			var payload = {};
			payload.request = configRequest();
			payload.script = 'Get File Config - DayBack';
			if (callback) {
				var callbackId = utilities.generateUID();
				dbk_fmFunctions[callbackId] = callback;
				payload.callback = callbackId;
			} else {
				payload.callback = true;
			}
			payload.dbk = true;
			var payloadString = JSON.stringify(payload);
			utilities.fileMakerCall(payload);
		}

		function setURLParameters(data) {
			var statuses = seedcodeCalendar.get('statuses');
			var resources = seedcodeCalendar.get('resources');
			var schedules = seedcodeCalendar.get('schedules');
			var parameters = data.payload.parameters;
			if (data.payload.resetFilters) {
				if (parameters.indexOf('filterStatuses=') !== -1) {
					for (var i = 0; i < statuses.length; i++) {
						statuses[i].status.selected = false;
					}
				}
				if (parameters.indexOf('filterResources=') !== -1) {
					for (var ii = 0; ii < resources.length; ii++) {
						resources[ii].status.selected = false;
					}
				}
			}
			if (
				data.payload.resetSources &&
				parameters.indexOf('source=') !== -1
			) {
				var parameterSplit = parameters.split('&');
				var sourceHash = {};
				for (var c = 0; c < parameterSplit.length; c++) {
					var split = parameterSplit[c].split('=');
					var name = split[0];
					var value = split[1];
					if (name === 'source') {
						sourceHash[value] = true;
					}
				}
				for (var s = 0; s < schedules.length; s++) {
					if (
						schedules[s].status.selected &&
						!sourceHash[schedules[s].name]
					) {
						manageSchedules.selectSchedule(schedules[s]);
					}
				}
			}

			location.hash = '#/?' + encodeParameters(parameters);

			function encodeParameters(parameters) {
				var split = parameters.split('&');
				for (var i = 0; i < split.length; i++) {
					split[i] = encodeThisParameter(split[i]);
				}
				return split.join('&');
				function encodeThisParameter(nameValue) {
					// use decodeURIComponent here in case the parameter has already been encoded
					var split = decodeURIComponent(nameValue).split('=');
					var result;
					if (split[1]) {
						split[1] = encodeURIComponent(split[1]);
						result = split.join('=');
					} else {
						result = nameValue;
					}
					return result;
				}
			}
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var schedules = [];
			var schedule;
			var sourceCount = 0;
			var resultCount = 0;

			//register our function for processing external edits at the global level so we can call from FM
			dbk_fmFunctions.processExternalEdit = processExternalEdit;
			dbk_fmFunctions.setURLParameters = setURLParameters;

			//run this to grab any url parameters
			loadFileMakerJSConfig(loadSchedules);

			function loadSchedules() {
				var haveKeys = 0;
				for (var s = 0; s < sources.length; s++) {
					if (sources[s].sourceTypeID === sourceTemplate.id) {
						sourceCount++;
					}
				}

				for (var i = 0; i < sources.length; i++) {
					if (sources[i].sourceTypeID === sourceTemplate.id) {
						//Apply our schedules
						schedule = setSchedule(sources[i]);
						var allKeys = Object.keys(schedule);
						var hasKeys = false;
						for (var k = 0; k < allKeys.length; k++) {
							if (
								allKeys[k] === 'contactPrimaryKey' ||
								allKeys[k] === 'projectPrimaryKey'
							) {
								hasKeys = true;
							}
						}
						if (hasKeys) {
							//related primary keys are in settings, newest version, we can proceed without hitting FM
							processResult(false, schedule);
						} else {
							//might be on new or old, try new config
							fileMakerLayoutTable(
								schedule.layoutName,
								processResult,
								schedule.name
							);
						}
					}
				}
			}

			function processResult(data, schedule) {
				resultCount++;
				if (data && data.result.indexOf('fieldData') === -1) {
					//new config is not set-up, so revert to old full call
					if (resultCount === 1) {
						//need to make older big call
						loadFileMakerJSConfig(setSchedules);
					}
					return;
				}

				if (!schedule && data) {
					//retrieve the schedule that made this call by name
					var result = JSON.parse(data.result);
					var scheduleName = result.schedule;
					for (var s = 0; s < sources.length; s++) {
						if (sources[s].name === scheduleName) {
							schedule = setSchedule(sources[s]);
						}
					}
				}

				if (data) {
					//newerlight call was made for related info for this schedule, so apply here
					//use our async call to populate related keys
					var thisConfig = {};
					thisConfig.payload = JSON.parse(data.result);
					var config = processFileMakerJSConfig(thisConfig, schedule);
					schedule.fieldData = config.fieldData;
					schedule.contactPrimaryKey = config.contactPrimaryKey;
					schedule.projectPrimaryKey = config.projectPrimaryKey;
				}

				//update schedules and proceed with main callback
				schedules.push(schedule);
				if (resultCount === sourceCount) {
					schedules.sort(compare);
					callback(schedules, sourceTemplate);
				}

				function compare(a, b) {
					if (a.id < b.id) {
						return -1;
					} else if (a.id > b.id) {
						return 1;
					} else {
						return 0;
					}
				}
			}

			function setSchedules(fmConfig) {
				//callback for old style config, backward compatability
				fileConfig = fmConfig;
				var schedules = [];
				var schedule;
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].sourceTypeID === sourceTemplate.id) {
						//Apply our schedules
						schedule = setSchedule(sources[i]);
						//add our valid fields and related info
						var config = processFileMakerJSConfig(
							fileConfig,
							schedule
						);
						schedule.fieldData = config.fieldData;
						schedule.contactPrimaryKey = config.contactPrimaryKey;
						schedule.projectPrimaryKey = config.projectPrimaryKey;
						schedules.push(schedule);
					}
				}
				//if we've retrieved a url parameter, then set it now.
				callback(schedules, sourceTemplate);
			}

			function fileMakerLayoutTable(layout, callback, scheduleName) {
				//call to FileMaker if the related primary keys are in question
				var payload = {};
				payload.script = 'Get Layout Table - DayBack';
				var request = {};
				request.layout = layout;
				request.schedule = scheduleName;
				payload.request = request;
				var callbackId = utilities.generateUID();
				dbk_fmFunctions[callbackId] = callback;
				payload.callback = callbackId;
				payload.dbk = true;
				utilities.fileMakerCall(payload);
			}

			function setSchedule(schedule) {
				//Clean schedule data
				schedule.sourceID = schedule.id;

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							customFieldMutateInput;
					}
				}

				//Clean schedule data
				calendarIO.cleanSchedule(schedule, sourceTemplate);
				return schedule;
			}
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function getUnscheduled(callbackFunc, schedule) {
			var fieldMap = fullFieldMap(schedule);
			var calendars = seedcodeCalendar.get('schedules');
			var request = {};
			var query = {};
			var queries = [];
			var allExpressions = {};
			var payload = {};

			var callback = manageFetch.create(
				schedule.id,
				'unscheduled',
				callbackFunc
			).confirm;

			if (
				!schedule.allowUnscheduled ||
				schedule.unusedMap?.unscheduled ||
				!schedule.fieldMap.unscheduled
			) {
				callback();
				return;
			}

			//query for data api, doesn't need context, use schedule fieldmap
			query[schedule.fieldMap.unscheduled] = true;
			queries.push(query);

			//get all calendar expressions
			for (var s = 0; s < calendars.length; s++) {
				if (calendars[s].sourceTypeID === 8) {
					allExpressions[calendars[s].name] = buildExpression(
						calendars[s]
					);
				}
			}

			// Build request
			request.config = configRequest();
			request.table = schedule.tableName;
			request.layout = schedule.layoutName;
			request.calendarName = schedule.name;
			request.allExpressions = allExpressions;
			request.fieldMap = fieldMap;
			request.expression = buildExpression(schedule);
			request.queries = queries;

			// Script payload
			payload.script = 'Find Events - DayBack';
			payload.request = request;

			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = processResult;
			payload.callback = callbackId;
			payload.dbk = true;

			// Call the filemaker script
			utilities.fileMakerCall(payload);

			function processResult(data) {
				if (
					data.status === 200 &&
					data.recordCount > 0 &&
					data.payload.length === 0
				) {
					message =
						'DayBack cannot find a mapped field or custom field. Please check your calendar set-up';
					utilities.showMessage(
						message,
						0,
						9000,
						'error',
						null,
						true
					);
					callback([]);
					return;
				} else if (data.status && data.status === 200) {
					var output;
					output = mutateFileMakerEvents(data.payload, schedule);
					callback(output);
				} else if (
					data.status === 500 &&
					data.payload &&
					data.payload.errorMessage
				) {
					//still may have gotten an error
					message = data.payload.errorMessage;
					utilities.showMessage(message, 0, 9000, 'error');
					callback([]);
					return;
				} else {
					message =
						'DayBack has encountered an unknown error. Please check your calendar set-up.';
					utilities.showMessage(
						message,
						0,
						9000,
						'error',
						null,
						true
					);
					callback([]);
					return;
				}
			}
		}

		function getMapEvents(bounds, callbackFunc, schedule, onError) {
			const geoFieldPrefix = 'DBk_Geocode_';
			const request = `${geoFieldPrefix}Latitude >= ${bounds.bottom} AND ${geoFieldPrefix}Latitude <= ${bounds.top} AND ${geoFieldPrefix}Longitude >= ${bounds.left} AND ${geoFieldPrefix}Longitude <= ${bounds.right}`;

			getEvents(
				null,
				null,
				null,
				callbackFunc,
				schedule,
				null,
				null,
				null,
				request
			);
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var message;
			var utcAdjust = 62135683200000;
			var fieldMap = fullFieldMap(schedule);

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			//validate layout
			if (fileConfig && fileConfig.payload) {
				var validLayouts = fileConfig.payload.layoutData;
				var validLayout = false;
				for (var i = 0; i < validLayouts.length; i++) {
					if (validLayouts[i] === schedule.layoutName) {
						validLayout = true;
					}
				}
				if (!validLayout) {
					message =
						schedule.layoutName +
						' is not a valid layout. Please check your layout name for ' +
						schedule.name +
						'.';
					utilities.showMessage(
						message,
						0,
						9000,
						'error',
						null,
						true
					);
					callback([]);
					return;
				}
			}

			//validate Field Map
			var fields = [];

			// for ( var mappedField in fieldMap ) {
			// 	if( mappedField!=='allDay' && fieldMap[mappedField] && !schedule.fieldData[fieldMap[mappedField]]){
			// 		fields.push(fieldMap[mappedField]);
			// 	}
			// }

			// if(fields.length > 0) {
			// 	message = 'DayBack cannot find the following mapped fields: ' + fields.join(', ') + '.';
			// 	utilities.showMessage(message, 0, 11000, 'error', null, true);
			// 	callback([]);
			// 	return;
			// }

			var statuses = seedcodeCalendar.get('statuses');
			var resources = seedcodeCalendar.get('resources');
			var textFilter = seedcodeCalendar.get('textFilters');
			var selectedResources = selectedFilters(resources);
			var selectedStatuses = selectedFilters(statuses);

			if (
				(schedule.requireResourceFilters &&
					selectedResources.length === 0) ||
				(schedule.requireFilters &&
					(!textFilter || textFilter.length === 0) &&
					selectedResources.length === 0 &&
					selectedStatuses.length === 0)
			) {
				//show toast message that filters are required.
				utilities.showMessage(
					'There are no required filters selected. Some selected calendars are configured to not show items in this situation. <a>Read more...</a>',
					0,
					7000,
					'message',
					function () {
						utilities.help('Speed', '137-speed');
					}
				);
				callback([]);
				return;
			}

			var request = {};
			var config = seedcodeCalendar.get('config');
			request.config = configRequest();
			request.table = schedule.tableName;
			request.layout = schedule.layoutName;
			request.calendarName = schedule.name;
			//query for data api, doesn't need context, use schedule fieldmap
			var query = {};
			var queries = [];
			if (requestOverride) {
				request.where = requestOverride;
			} else {
				//end validation, start building our query
				var timeStampStart =
					(moment(start)
						.utcOffset(0, true)
						.subtract(1, 'day')
						.valueOf() +
						utcAdjust) /
					1000;
				var timeStampEnd =
					(moment(end)
						.utcOffset(0, true)
						.subtract(1, 'day')
						.valueOf() +
						utcAdjust) /
					1000;
				query[schedule.fieldMap.start] = '≤' + timeStampEnd;
				query[schedule.fieldMap.end] = '≥' + timeStampStart;
				query['omit'] = 'false';
				request.where =
					schedule.fieldMap.start +
					'<=' +
					timeStampEnd +
					' AND ' +
					schedule.fieldMap.end +
					'>=' +
					timeStampStart;
			}
			var clone;

			if (
				schedule.includeFilters &&
				(selectedResources.length > 0 || selectedStatuses.length > 0)
			) {
				if (selectedResources.length > 0) {
					request.where += ' AND (';
					for (var rr = 0; rr < selectedResources.length; rr++) {
						selectedResources[rr] = selectedResources[rr].replace(
							/'/g,
							"\\'"
						);
						if (rr > 0) {
							request.where += ' OR ';
						}
						if (selectedResources[rr] === config.noFilterLabel) {
							request.where +=
								'"' +
								schedule.fieldMap.resource +
								'" IS NULL OR';
						}
						request.where +=
							'"' +
							schedule.fieldMap.resource +
							'" LIKE \'%' +
							selectedResources[rr] +
							"%'";
					}
					request.where += ')';
				}
				if (selectedStatuses.length > 0) {
					request.where += ' AND (';
					for (var ss = 0; ss < selectedStatuses.length; ss++) {
						selectedStatuses[ss] = selectedStatuses[ss].replace(
							/'/g,
							"\\'"
						);
						if (ss > 0) {
							request.where += ' OR ';
						}
						if (selectedStatuses[ss] === config.noFilterLabel) {
							request.where +=
								'"' + schedule.fieldMap.status + '" IS NULL OR';
						}
						request.where +=
							'"' +
							schedule.fieldMap.status +
							'" LIKE \'%' +
							selectedStatuses[ss] +
							"%'";
					}
					request.where += ')';
				}

				for (var r = 0; r < selectedResources.length; r++) {
					//add resources to queries
					if (selectedResources[r] === config.noFilterLabel) {
						clone = JSON.parse(JSON.stringify(query));
						clone[schedule.fieldMap.resource] = '=';
						queries.push(clone);
					}
					clone = JSON.parse(JSON.stringify(query));
					clone[schedule.fieldMap.resource] = selectedResources[r];
					queries.push(clone);
				}
				for (var s = 0; s < selectedStatuses.length; s++) {
					//no resources, so just add requests for statuses
					if (selectedStatuses[s] === config.noFilterLabel) {
						clone = JSON.parse(JSON.stringify(query));
						clone[schedule.fieldMap.status] = '=';
						queries.push(clone);
					}
					clone = JSON.parse(JSON.stringify(query));
					clone[schedule.fieldMap.status] = selectedStatuses[s];
					queries.push(clone);
				}
			} else {
				queries.push(query);
			}

			//get all calendar expressions
			var allExpressions = {};
			var calendars = seedcodeCalendar.get('schedules');
			for (var s = 0; s < calendars.length; s++) {
				if (calendars[s].sourceTypeID === 8) {
					allExpressions[calendars[s].name] = buildExpression(
						calendars[s]
					);
				}
			}
			request.allExpressions = allExpressions;
			request.fieldMap = fieldMap;
			request.expression = buildExpression(schedule);
			request.queries = queries;
			var payload = {};
			payload.script = 'Find Events - DayBack';
			payload.request = request;
			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = processResult;
			payload.callback = callbackId;
			payload.dbk = true;

			// Run before events fetched action
			const actionCallbacks = {
				confirm: function () {
					utilities.fileMakerCall(payload);
				},
				cancel: function () {
					callback(mutateFileMakerEvents([], schedule));
				},
			};

			const actionResult = calendarIO.runEventActions(
				'beforeEventsFetched',
				{schedule: schedule},
				true,
				actionCallbacks,
				null
			);

			if (!actionResult) {
				return;
			}

			utilities.fileMakerCall(payload);

			function processResult(data) {
				if (
					data.status === 200 &&
					data.recordCount > 0 &&
					data.payload.length === 0
				) {
					message =
						'DayBack cannot find a mapped field or custom field. Please check your calendar set-up';
					utilities.showMessage(
						message,
						0,
						9000,
						'error',
						null,
						true
					);
					callback([]);
					return;
				} else if (data.status && data.status === 200) {
					var output;
					// if (data.payload && data.payload.messages){
					// 	//result is from data api
					// 	output = mutateDataAPIPayload(data.payload);
					// 	output = mutateFileMakerEvents(output,schedule);
					// }
					// else {
					output = mutateFileMakerEvents(data.payload, schedule);
					// }
					callback(output);
				} else if (
					data.status === 500 &&
					data.payload &&
					data.payload.errorMessage
				) {
					//still may have gotten an error
					message = data.payload.errorMessage;
					utilities.showMessage(message, 0, 9000, 'error');
					callback([]);
					return;
				} else {
					message =
						'DayBack has encountered an unknown error. Please check your calendar set-up.';
					utilities.showMessage(
						message,
						0,
						9000,
						'error',
						null,
						true
					);
					callback([]);
					return;
				}
			}
		}

		function selectedFilters(filters) {
			var result = [];
			for (var i = 0; i < filters.length; i++) {
				if (
					filters[i].status &&
					filters[i].status.selected &&
					!filters[i].isFolder
				) {
					result.push(filters[i].name);
				}
			}
			return result;
		}

		function customFieldMutateInput(item, fieldDefinition, schedule) {
			if (!item && fieldDefinition.formatas !== 'select') {
				return null;
			}
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						item = item.replace(/\r/g, '\n');
						result = item.split('\n');
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else if (fieldDefinition.formatas === 'date') {
				result = moment(item).format(schedule.fileDateFormat);
			} else if (fieldDefinition.formatas === 'timestamp') {
				result = moment(item).format(schedule.fileTimestampFormat);
			} else if (fieldDefinition.formatas === 'checkbox') {
				result = !!+item;
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				//Test for date time field vs date field
				if (customField.dataFormat === 'datetime') {
					return item
						? moment(item).format('YYYY+MM+DD HH:mm:ss')
						: '';
				} else {
					return item ? moment(item).format('YYYY+MM+DD') : '';
				}
			} else if (customField && customField.formatas === 'timestamp') {
				return item ? moment(item).format('YYYY+MM+DD HH:mm:ss') : '';
			} else if (customField && customField.formatas === 'checkbox') {
				return item ? 1 : null;
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return item.join('\n');
			} else {
				return item;
			}
		}

		// function mutateDataAPIPayload(payload){
		// 	var result = [];
		// 	var data = payload.response.data;
		// 	for ( var i = 0 ; i < data.length ; i++ ) {
		// 		result.push(JSON.parse(data[i].fieldData.DBk_JSON));
		// 	}
		// 	return result;
		// }

		function mutateFileMakerEvents(events, schedule) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			var output = [];
			for (var i = 0; i < events.length; i++) {
				output.push(mutateEvent(events[i], schedule));
			}
			//Return our result
			return output;

			function mutateEvent(eventArray, schedule) {
				var fields = mappedFields(schedule);
				var event = {};
				for (var i = 0; i < eventArray.length - 1; i++) {
					event[fields[i]] = eventArray[i];
				}

				//now transform
				var startTime;
				var endTime;
				if (!event.timeStart || event.timeStart.length === 0) {
					event.allDay = true;
				} else {
					event.allDay = false;
				}

				event.eventSource = schedule.id;
				event.schedule = schedule;

				//Add protection against old calcs that substitute return for \n
				event.title = event.title.replace(/\\n/g, '\n');

				//convert status to array
				//replace carriage return with new lines
				event.status = event.status
					? event.status.replace(/\r/g, '\n')
					: event.status;
				event.status =
					!event.status || event.status === ''
						? []
						: event.status.split('\n');

				//convert resource to array
				//replace carriage return with new lines
				event.resource = event.resource
					? event.resource.replace(/\r/g, '\n')
					: event.resource;
				event.resource =
					!event.resource || event.resource === ''
						? []
						: event.resource.split('\n');

				//convert contact to array
				//replace carriage return with new lines
				event.contactName = event.contactName
					? event.contactName.replace(/\r/g, '\n')
					: event.contactName;
				event.contactName =
					!event.contactName || event.contactName === ''
						? []
						: event.contactName.split('\n');
				//replace carriage return with new lines
				event.contactID = event.contactID
					? event.contactID.replace(/\r/g, '\n')
					: event.contactID;
				event.contactID =
					!event.contactID || event.contactID === ''
						? []
						: event.contactID.split('\n');

				//convert project to array
				//replace carriage return with new lines
				event.projectName = event.projectName
					? event.projectName.replace(/\r/g, '\n')
					: event.projectName;
				event.projectName =
					!event.projectName || event.projectName === ''
						? []
						: event.projectName.split('\n');
				//replace carriage return with new lines
				event.projectID = event.projectID
					? event.projectID.replace(/\r/g, '\n')
					: event.projectID;
				event.projectID =
					!event.projectID || event.projectID === ''
						? []
						: event.projectID.split('\n');

				// Assign booleans
				event.unscheduled =
					event.unscheduled === '1' || event.unscheduled === 'true'
						? true
						: false;

				// Protect against question marks
				event.start = event.start === '?' ? null : event.start;
				event.end = event.end === '?' ? null : event.end;

				//Convert number to moment
				if (event.start) {
					startTime = moment(
						(event.start - 62135683200) * 1000 + 86400000
					);
					//Convert to utc moment and output date string. Then create a moment from that converted to local time
					event.start = $.fullCalendar.moment(
						moment.utc(startTime).format('YYYY-MM-DD HH:mm:ss')
					);
				}

				//Fix data before returning result
				if (!event.end && event.start) {
					event.end = event.start.clone();
				} else {
					//Convert number to moment
					if (event.end) {
						endTime = moment(
							(event.end - 62135683200) * 1000 + 86400000
						);
						//Convert to utc moment and output date string. Then create a moment from that converted to local time
						event.end = moment(
							moment.utc(endTime).format('YYYY-MM-DD HH:mm:ss')
						);
					}
				}

				// Prep geocode
				if (event.geocode) {
					const geocodeParts = event.geocode.split(',');
					event.geocode = {
						lat: parseFloat(geocodeParts[0]?.trim()),
						lng: parseFloat(geocodeParts[1]?.trim()),
					};
					if (isNaN(event.geocode.lat) || isNaN(event.geocode.lng)) {
						event.geocode = '';
					}
				}

				//return mulated event
				return calendarIO.cleanEvent(event);
			}
		}

		//Edit Events

		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			var config = seedcodeCalendar.get('config');
			var customFields = schedule.customFields;
			var editObj = {};
			var translatedProperty;
			var fieldMap = fullFieldMap(schedule);
			var message;

			//validate layout
			if (fileConfig && fileConfig.payload) {
				var validLayouts = fileConfig.payload.layoutData;
				var validLayout = false;
				for (var i = 0; i < validLayouts.length; i++) {
					if (validLayouts[i] === schedule.layoutName) {
						validLayout = true;
					}
				}
				if (!validLayout) {
					message =
						schedule.layoutName +
						' is not a valid layout. Please check your settings for ' +
						schedule.name +
						'.';
					utilities.showMessage(message, 0, 9000, 'error');
					callback({});
					return;
				}
			}

			//validate Field Map
			// var fields = [];
			// for ( var mappedField in fieldMap ) {
			// 	if(fieldMap[mappedField] && !schedule.fieldData[fieldMap[mappedField]]){
			// 		fields.push(fieldMap[mappedField]);
			// 	}
			// }
			// if(fields.length > 0) {
			// 	message = 'DayBack cannot find the following mapped fields: ' + fields.join(', ') + '.';
			// 	utilities.showMessage(message, 0, 11000, 'error');
			// 	callback({});
			// 	return;
			// }

			//initial validation passed, start building changes and request
			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is
				if (
					property !== 'allDay' &&
					property !== 'eventID' &&
					property !== 'title' &&
					property !== 'projectName' &&
					property !== 'contactName' &&
					changes.hasOwnProperty(property)
				) {
					translatedProperty = fieldMap[property];
					if (property === 'start') {
						editObj[fieldMap.dateStart] = moment(
							changes.start
						).format('YYYY+MM+DD');
						editObj[fieldMap.timeStart] = event.allDay
							? ''
							: moment(changes.start).format('HH:mm:ss');
					} else if (property === 'end') {
						if (
							(!schedule.unusedMap && fieldMap.dateEnd) ||
							(fieldMap.dateEnd &&
								schedule.unusedMap &&
								!schedule.unusedMap.dateEnd)
						) {
							editObj[fieldMap.dateEnd] = event.allDay
								? moment(changes.end)
										.subtract(1, 'days')
										.format('YYYY+MM+DD')
								: moment(changes.end).format('YYYY+MM+DD');
						}
						if (
							(!schedule.unusedMap && fieldMap.timeEnd) ||
							(fieldMap.timeEnd &&
								schedule.unusedMap &&
								!schedule.unusedMap.timeEnd)
						) {
							editObj[fieldMap.timeEnd] = event.allDay
								? ''
								: moment(changes.end).format('HH:mm:ss');
						}
					}
					//Resources need to be called out so we can remove any stored "none" items and make them empty values
					else if (property === 'resource' || property === 'status') {
						editObj[translatedProperty] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (
							var i = 0;
							i < editObj[translatedProperty].length;
							i++
						) {
							if (
								editObj[translatedProperty][i] ===
								config.noFilterLabel
							) {
								editObj[translatedProperty][i] = '';
							}
						}
						editObj[translatedProperty] =
							editObj[translatedProperty].join('\r');
					} else if (property === 'geocode') {
						editObj[translatedProperty] = !changes[property]
							? changes[property]
							: `${changes[property].lat},${changes[property].lng}`;
					} else if (property === 'tags') {
						editObj[translatedProperty] =
							calendarIO.packageTagsOutput(event[property]);
					} else if (customFields && customFields[property]) {
						editObj[translatedProperty] =
							calendarIO.customFieldOutputTransform(
								property,
								changes[property],
								customFields,
								schedule,
								customFieldMutateOutput
							);
					}
					//Join arrays
					else if (Array.isArray(changes[property])) {
						editObj[translatedProperty] =
							changes[property].join('\r');
					} else {
						editObj[translatedProperty] = changes[property];
					}
				}
			}

			var request = {};
			request.layout = schedule.layoutName;
			request.table = schedule.tableName;
			request.calendarName = schedule.name;
			request.fieldMap = fieldMap;
			request.expression = buildExpression(schedule);
			request.editObject = editObj;
			request.id = event.eventID;
			request.idField = fieldMap.eventID;
			request.isDelete = false;
			request.config = configRequest();

			var payload = {};
			payload.script = 'Edit Event - DayBack';
			payload.request = request;
			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = resultCallback;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);

			function resultCallback(data) {
				processResult(
					data,
					event,
					revertObject,
					revertFunc,
					editID,
					callback,
					schedule
				);
			}
		}

		function processResult(
			data,
			event,
			revertObject,
			revertFunc,
			editID,
			callback,
			schedule
		) {
			var config = seedcodeCalendar.get('config');
			var isNew = !event.eventID ? true : false;
			var element = seedcodeCalendar.get('element');
			var message;
			if (data.status === 200) {
				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						processResult(
							data,
							event,
							revertObject,
							revertFunc,
							editID,
							callback,
							schedule
						);
					}, 250);
					return;
				}
				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}
				var eventResult = mutateFileMakerEvents(
					[data.payload],
					schedule
				)[0];

				// Normalize dates
				seedcodeCalendar
					.get('element')
					.fullCalendar('buildEventDates', eventResult, eventResult);

				var modified = applyEventChanges(event, eventResult) || isNew;

				if (modified) {
					if (event.start && event.end && event.allDay) {
						event.start.stripTime();
						event.end.stripTime();
					}
					fullCalendarBridge.update(element, event);
				}

				//Run a callback if one is available
				if (callback) {
					callback(event);
				}

				return {
					modified: modified,
					event: event,
				};
			} else {
				if (config.passthroughEditErrors) {
					if (callback) {
						callback(null);
					}
				} else if (data.status === 500) {
					message = $sce.trustAsHtml(
						'There was an error saving the event and your changes will be reverted.<p>' +
							+data.payload.errorCode +
							': ' +
							errorDescription(data.payload.errorCode) +
							'</p>' +
							data.payload.errorMessage
					);
				} else {
					message =
						'There was an unknown error saving the event and your changes will be reverted.';
				}
				if (revertFunc) {
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Revert Changes',
						dragError
					);
				} else if (revertObject) {
					utilities.showModal(
						'Operation Failed',
						message,
						'Return To Event',
						cancelError,
						'Revert Changes',
						confirmError
					);
				}
			}
			function dragError() {
				if (revertFunc) {
					revertFunc(null, event, data.payload.errorCode);
				}
			}
			function confirmError() {
				if (event.eventID) {
					applyEventChanges(event, revertObject);
					calendarIO.assignEventColor(event);
					fullCalendarBridge.update(element, event);
					if (callback) {
						callback(false);
					}
				} else {
					//Remove event from view
					fullCalendarBridge.removeEvents(element, event);
				}
			}
			function cancelError() {
				if (!event.eventStatus) {
					event.eventStatus = {};
				}

				event.eventStatus.revertObject = revertObject;

				if (event.eventStatus.isCustomAction) {
					applyEventChanges(event, revertObject);
				} else {
					event.eventStatus.showPopover = true;
					event.eventStatus.unsavedChanges = true;
					fullCalendarBridge.update(element, event);
					//Reset event status for custom action state
					event.eventStatus.isCustomAction = false;
				}
			}
		}

		//Delete Event

		function deleteEvent(event, callback, schedule) {
			var fieldMap = fullFieldMap(schedule);
			var id = event.eventID;

			var request = {};
			request.layout = schedule.layoutName;
			request.table = schedule.tableName;
			request.calendarName = schedule.name;
			request.fieldMap = fieldMap;
			request.expression = buildExpression(schedule);
			request.id = event.eventID;
			request.idField = fieldMap.eventID;
			request.isDelete = true;
			request.config = configRequest();

			var payload = {};
			payload.script = 'Edit Event - DayBack';
			payload.request = request;
			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = processDelete;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);

			function processDelete(data) {
				var message;
				if (data.status === 204) {
					calendarIO.confirmDelete(event, data.errorCode, callback);
				} else {
					if (data.status === 500) {
						message = $sce.trustAsHtml(
							'There was an error deleting the event and your changes will be reverted.<p>' +
								+data.payload.errorCode +
								': ' +
								errorDescription(data.payload.errorCode) +
								'</p>' +
								data.payload.errorMessage
						);
					} else {
						message =
							'There was an unknown error saving the event and your changes will be reverted.';
					}
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
				}
			}
			function confirmError() {
				return;
			}
		}

		function deleteRepeatingInstance(event, callback) {
			deleteEvent(event, callback, event.schedule);
		}

		function showDetail(id, editEvent, type) {
			var thisId = id[0];
			var layout;
			var idField;
			var message;
			var fieldMap = editEvent.fieldMap;
			var table = editEvent.schedule.tableName;
			if (
				type === 'project' &&
				editEvent.schedule.projectData &&
				editEvent.schedule.projectData.navigationLayout
			) {
				layout = editEvent.schedule.projectData.navigationLayout;
				idField = editEvent.schedule.projectPrimaryKey;
			}
			if (
				type === 'contact' &&
				editEvent.schedule.contactData &&
				editEvent.schedule.contactData.navigationLayout
			) {
				layout = editEvent.schedule.contactData.navigationLayout;
				idField = editEvent.schedule.contactPrimaryKey;
			}

			if (!layout) {
				message =
					'A navigation layout for ' +
					type +
					' has not been set-up for ' +
					editEvent.event.schedule.name +
					'.';
				utilities.showMessage(message, 0, 9000, 'error');
				return;
			}

			//validate layout
			if (fileConfig && fileConfig.payload) {
				var validLayouts = fileConfig.payload.layoutData;
				var validLayout = false;
				for (var i = 0; i < validLayouts.length; i++) {
					if (validLayouts[i] === layout) {
						validLayout = true;
					}
				}
				if (!validLayout) {
					message =
						layout +
						' is not a valid layout. Please check your settings for ' +
						editEvent.event.schedule.name +
						'.';
					utilities.showMessage(message, 0, 9000, 'error');
					return;
				}
			}

			var request = {
				id: thisId,
				idField: idField,
				type: type,
				layout: layout,
			};
			request.calendarName = editEvent.event.schedule.name;
			var payload = {};
			payload.script = 'Open Related Record - DayBack';
			payload.request = request;
			payload.dbk = true;

			utilities.fileMakerCall(payload);
		}

		function goToEvent(layout, event) {
			var id = event.eventID;
			var idField =
				event.schedule.tableName +
				'::' +
				event.schedule.fieldMap.eventID;
			var request = {};
			request.id = id;
			request.idField = idField;
			request.layout = layout;
			var payload = {};
			payload.script = 'Go To Event - DayBack';
			payload.request = request;
			payload.dbk = true;
			utilities.fileMakerCall(payload);
		}

		function showEventOnLayout(layout, event) {
			fromButtonAction = false;
			if (event.event) {
				fromButtonAction = true;
				event = event.event;
				seedcodeCalendar.init('closePopovers', true);
			}
			var fieldMap = fullFieldMap(event.schedule);
			var customFields = event.schedule.customFields;
			currentSchedule = event.schedule;
			var id = event.eventID;
			var newRecord = '';
			if (!id) {
				newEvent = event;
				//build payload for new record
				newRecord = {};
				for (var property in event) {
					if (property == 'start') {
						newRecord[fieldMap.dateStart] = {};
						newRecord[fieldMap.dateStart].type = 'date';
						newRecord[fieldMap.dateStart].value = moment(
							event.start
						).format(event.schedule.fileDateFormat);
						if (fieldMap.timeStart) {
							newRecord[fieldMap.timeStart] = {};
							newRecord[fieldMap.timeStart].type = 'time';
							newRecord[fieldMap.timeStart].value = event.allDay
								? ''
								: moment(event.start).format('HH:mm:ss');
						}
					} else if (property == 'end') {
						if (fieldMap.dateEnd) {
							newRecord[fieldMap.dateEnd] = {};
							newRecord[fieldMap.dateEnd].type = 'date';
							newRecord[fieldMap.dateEnd].value = event.allDay
								? moment(event.end)
										.subtract(1, 'days')
										.format(event.schedule.fileDateFormat)
								: moment(event.end).format(
										event.schedule.fileDateFormat
									);
						}
						if (fieldMap.timeEnd) {
							newRecord[fieldMap.timeEnd] = {};
							newRecord[fieldMap.timeEnd].type = 'time';
							newRecord[fieldMap.timeEnd].value = event.allDay
								? ''
								: moment(event.end).format('HH:mm:ss');
						}
					} else if (customFields && customFields[property]) {
						newRecord[fieldMap[property]] = {};

						newRecord[fieldMap[property]].value =
							calendarIO.customFieldOutputTransform(
								property,
								event[property],
								customFields,
								event.schedule,
								customFieldMutateOutput
							);
						newRecord[fieldMap[property]].type =
							typeof event[property];
					} else if (
						fieldMap[property] &&
						typeof event[property] === 'object' &&
						event[property] !== null
					) {
						newRecord[fieldMap[property]] = {};
						newRecord[fieldMap[property]].type = 'array';
						newRecord[fieldMap[property]].value = event[property];
					} else if (fieldMap[property] && property !== 'title') {
						newRecord[fieldMap[property]] = {};
						newRecord[fieldMap[property]].type =
							typeof event[property];
						newRecord[fieldMap[property]].value = event[property];
					}
				}
			}

			var idField =
				event.schedule.tableName +
				'::' +
				event.schedule.fieldMap.eventID;
			var type = event.schedule.name;
			var request = {
				id: id,
				idField: idField,
				type: type,
				layout: layout,
				newRecord: newRecord,
			};
			request.calendarName = event.schedule.name;
			request.config = configRequest();
			request.config.currentFieldMap = fieldMap;
			var payload = {};
			payload.script = 'Open Event Record - DayBack';
			payload.request = request;
			payload.dbk = true;

			utilities.fileMakerCall(payload);
		}

		// get contacts by object and criteria for contact selector

		function getContacts(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			var request = {};
			request.layout = object;
			request.table = searchField.split('::')[0];
			request.calendarName = schedule.name;
			request.searchField = schedule.contactData.searchField;
			var idField = schedule.contactPrimaryKey;
			var displayField = schedule.contactData.displayField;
			if (idField.indexOf('::') !== -1) {
				idField = idField.split('::')[1];
			}
			if (displayField.indexOf('::') !== -1) {
				displayField = displayField.split('::')[1];
			}
			request.displayField = displayField;
			request.idField = idField;
			request.criteria = criteria;
			request.config = configRequest();

			var payload = {};
			payload.script = 'Look Up Related - DayBack';
			payload.request = request;
			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = processContacts;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);
			function processContacts(data) {
				var message;
				if (data.status === 200) {
					var records = data.payload;
					for (var i = 0; i < records.length; i++) {
						records[i].dbkDisplay = records[i].Name;
						records[i].dbkDisplayH = $sce.trustAsHtml(
							'<span>' + records[i].Name + '</span>'
						);
					}
					records.sort(compare);
					callback(records);
				} else {
					if (data.status === 500) {
						message =
							'There was an error searching for Contacts: ' +
							data.payload.errorMessage;
					} else {
						message =
							'There was an unknown error searching for Contacts.';
					}
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'OK',
						confirmError
					);
				}
				function confirmError() {
					return;
				}
			}
			function compare(a, b) {
				if (a.Name < b.Name) {
					return -1;
				} else if (a.Name > b.Name) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		function getProjects(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			var request = {};
			request.layout = object;
			request.table = searchField.split('::')[0];
			request.calendarName = schedule.name;
			request.searchField = searchField;
			var idField = schedule.projectPrimaryKey;
			var displayField = schedule.projectData.displayField;
			if (idField.indexOf('::') !== -1) {
				idField = idField.split('::')[1];
			}
			if (displayField.indexOf('::') !== -1) {
				displayField = displayField.split('::')[1];
			}
			request.displayField = displayField;
			request.idField = idField;
			request.criteria = criteria;
			request.config = configRequest();

			var payload = {};
			payload.script = 'Look Up Related - DayBack';
			payload.request = request;
			//define first callback
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = processProjects;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);
			function processProjects(data) {
				var message;
				if (data.status === 200) {
					var records = data.payload;
					for (var i = 0; i < records.length; i++) {
						records[i].dbkDisplay = records[i].Name;
						records[i].dbkDisplayH = $sce.trustAsHtml(
							'<span>' + records[i].Name + '</span>'
						);
					}
					records.sort(compare);
					callback(records);
				} else {
					if (data.status === 500) {
						message =
							'There was an error searching for Projects: ' +
							data.payload.errorMessage;
					} else {
						message =
							'There was an unknown error searching for Projects.';
					}
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'OK',
						confirmError
					);
				}
				function confirmError() {
					return;
				}
			}
			function compare(a, b) {
				if (a.Name < b.Name) {
					return -1;
				} else if (a.Name > b.Name) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		function applyEventChanges(event, changes) {
			var modified;
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					typeof changes[property] === 'undefined' ||
					typeof changes[property] === 'string' ||
					typeof changes[property] === 'number' ||
					typeof changes[property] === 'boolean' ||
					(typeof changes[property] === 'object' &&
						changes[property] === null)
					//&& (property !== 'dateStart' && property !== 'dateEnd' && property !== 'timeStart' && property !== 'timeEnd')
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (
							property !== 'eventID' &&
							property !== 'className'
						) {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (moment.isMoment(changes[property])) {
					if (
						(changes.allDay &&
							!event[property].isSame(changes[property])) ||
						(!changes.allDay &&
							event[property].valueOf() !==
								changes[property].valueOf())
					) {
						modified = true;
						event[property] = $.fullCalendar.moment(
							changes[property].clone().toArray()
						);
					}
					// else if(!changes.allDay && ( event[property].valueOf() !== changes[property].valueOf())){
					// 	modified = true;
					// 	event[property] = changes[property];
					// }
				}
			}
			return modified;
		}

		function processFileMakerJSConfig(data, schedule) {
			var result = 0;
			var fieldData = data.payload.fieldData;
			var layoutData = data.payload.layoutData;
			var relationshipData = data.payload.relationshipData;
			var layouts = fileMakerSelectList(layoutData);
			var tableHash = {};
			var tables = [];
			var tableName = schedule.tableName;
			var fields = {};
			var fieldMap = schedule.fieldMap;

			for (var i = 0; i < fieldData.length; i++) {
				var fieldArray = fieldData[i];
				var fieldTable = fieldArray[0];
				var fieldName = fieldArray[1];
				if (fieldTable === schedule.tableName) {
					fields[fieldTable + '::' + fieldName] = true;
				}
			}
			var contactName =
				schedule.fieldMap && schedule.fieldMap.contactName
					? schedule.fieldMap.contactName
					: '';
			var projectName =
				schedule.fieldMap && schedule.fieldMap.projectName
					? schedule.fieldMap.projectName
					: '';
			var contactTable = contactName ? contactName.split('::')[0] : '';
			var projectTable = projectName ? projectName.split('::')[0] : '';

			if (
				relationshipData[tableName] &&
				relationshipData[tableName][contactTable]
			) {
				var contactNames =
					relationshipData[tableName][contactTable].nameFields || [];
				for (var c = 0; c < contactNames.length; c++) {
					fields[contactTable + '::' + contactNames[c]] = true;
				}
			}
			if (
				relationshipData[tableName] &&
				relationshipData[tableName][projectTable]
			) {
				var projectNames =
					relationshipData[tableName][projectTable].nameFields || [];
				for (var p = 0; p < projectNames.length; p++) {
					fields[projectTable + '::' + projectNames[p]] = true;
				}
			}

			var contactForeignKey = tableName + '::' + fieldMap.contactID;
			var projectForeignKey = tableName + '::' + fieldMap.projectID;
			var contactPrimaryKey = '';
			var projectPrimaryKey = '';
			var table;
			if (contactForeignKey) {
				for (table in relationshipData[tableName]) {
					if (
						relationshipData[tableName][table].fk[0] ===
						contactForeignKey
					) {
						contactPrimaryKey =
							relationshipData[tableName][table].pk[0];
					}
				}
			}
			if (projectForeignKey) {
				for (var table in relationshipData[tableName]) {
					if (
						relationshipData[tableName][table].fk[0] ===
						projectForeignKey
					) {
						projectPrimaryKey =
							relationshipData[tableName][table].pk[0];
					}
				}
			}

			return {
				fieldData: fields,
				contactPrimaryKey: contactPrimaryKey,
				projectPrimaryKey: projectPrimaryKey,
			};

			function getField(tableName, sources) {
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].tableName === tableName) {
						return sources[i];
					}
				}
			}

			function fileMakerSelectList(data) {
				var result = [newLine()];
				for (var i = 0; i < data.length; i++) {
					result.push(newLine(data[i]));
				}
				return result;
			}
			function newLine(field) {
				if (!field) {
					field = '';
				}
				return {id: field, label: field};
			}
		}

		//Descriptions of FileMaker Error Codes
		// returns english text for an error
		// from Jerimiah Small simpleFM.php - http://www.jsmall.us/downloads/
		// via simpleFMProxy Todd Geist <todd@geistinterctive.com>
		function errorDescription(code) {
			var obj = {
				'-2': "Couldn't connect to the FileMaker Server. Check you user name and password, and check you server configuration",
				'-1': 'Unknown error',
				0: 'No error',
				1: 'User canceled action',
				2: 'Memory error',
				3: 'Command is unavailable (for example, wrong operating system, wrong mode, etc.)',
				4: 'Command is unknown',
				5: 'Command is invalid (for example, a Set Field script step does not have a calculation specified)',
				6: 'File is read-only',
				7: 'Running out of memory',
				8: 'Empty result',
				9: 'Insufficient privileges',
				10: 'Requested data is missing',
				11: 'Name is not valid',
				12: 'Name already exists',
				13: 'File or object is in use',
				14: 'Out of range',
				15: "Can't divide by zero",
				16: 'Operation failed, request retry (for example, a user query)',
				17: 'Attempt to convert foreign character set to UTF-16 failed',
				18: 'Client must provide account information to proceed',
				19: 'String contains characters other than A-Z, a-z, 0-9 (ASCII)',
				100: 'File is missing',
				101: 'Record is missing',
				102: 'Field is missing',
				103: 'Relationship is missing',
				104: 'Script is missing',
				105: 'Layout is missing',
				106: 'Table is missing',
				107: 'Index is missing',
				108: 'Value list is missing',
				109: 'Privilege set is missing',
				110: 'Related tables are missing',
				111: 'Field repetition is invalid',
				112: 'Window is missing',
				113: 'Function is missing',
				114: 'File reference is missing',
				130: 'Files are damaged or missing and must be reinstalled',
				131: 'Language pack files are missing (such as template files)',
				200: 'Record access is denied',
				201: 'Field cannot be modified',
				202: 'Field access is denied',
				203: "No records in file to print, or password doesn't allow print access",
				204: 'No access to field(s) in sort order',
				205: 'User does not have access privileges to create new records; import will overwrite existing data',
				206: 'User does not have password change privileges, or file is not modifiable',
				207: 'User does not have sufficient privileges to change database schema, or file is not modifiable',
				208: 'Password does not contain enough characters',
				209: 'New password must be different from existing one',
				210: 'User account is inactive',
				211: 'Password has expired',
				212: 'Invalid user account and/or password. Please try again',
				213: 'User account and/or password does not exist',
				214: 'Too many login attempts',
				215: 'Administrator privileges cannot be duplicated',
				216: 'Guest account cannot be duplicated',
				217: 'User does not have sufficient privileges to modify administrator account',
				300: 'File is locked or in use',
				301: 'Record is in use by another user',
				302: 'Table is in use by another user',
				303: 'Database schema is in use by another user',
				304: 'Layout is in use by another user',
				306: 'Record modification ID does not match',
				400: 'Find criteria are empty',
				401: 'No records match the request',
				402: 'Selected field is not a match field for a lookup',
				403: 'Exceeding maximum record limit for trial version of FileMaker Pro',
				404: 'Sort order is invalid',
				405: 'Number of records specified exceeds number of records that can be omitted',
				406: 'Replace/Reserialize criteria are invalid',
				407: 'One or both match fields are missing (invalid relationship)',
				408: 'Specified field has inappropriate data type for this operation',
				409: 'Import order is invalid',
				410: 'Export order is invalid',
				412: 'Wrong version of FileMaker Pro used to recover file',
				413: 'Specified field has inappropriate field type',
				414: 'Layout cannot display the result',
				500: 'Date value does not meet validation entry options',
				501: 'Time value does not meet validation entry options',
				502: 'Number value does not meet validation entry options',
				503: 'Value in field is not within the range specified in validation entry options',
				504: 'Value in field is not unique as required in validation entry options',
				505: 'Value in field is not an existing value in the database file as required in validation entry options',
				506: 'Value in field is not listed on the value list specified in validation entry option',
				507: 'Value in field failed calculation test of validation entry option',
				508: 'Invalid value entered in Find mode',
				509: 'Field requires a valid value',
				510: 'Related value is empty or unavailable',
				511: 'Value in field exceeds maximum number of allowed characters',
				600: 'Print error has occurred',
				601: 'Combined header and footer exceed one page',
				602: "Body doesn't fit on a page for current column setup",
				603: 'Print connection lost',
				700: 'File is of the wrong file type for import',
				706: 'EPSF file has no preview image',
				707: 'Graphic translator cannot be found',
				708: "Can't import the file or need color monitor support to import file",
				709: 'QuickTime movie import failed',
				710: 'Unable to update QuickTime file reference because the database file is read-only',
				711: 'Import translator cannot be found',
				714: 'Password privileges do not allow the operation',
				715: 'Specified Excel worksheet or named range is missing',
				716: 'A SQL query using DELETE, INSERT, or UPDATE is not allowed for ODBC import',
				717: 'There is not enough XML/XSL information to proceed with the import or export',
				718: 'Error in parsing XML file (from Xerces)',
				719: 'Error in transforming XML using XSL (from Xalan)',
				720: 'Error when exporting; intended format does not support repeating fields',
				721: 'Unknown error occurred in the parser or the transformer',
				722: 'Cannot import data into a file that has no fields',
				723: 'You do not have permission to add records to or modify records in the target table',
				724: 'You do not have permission to add records to the target table',
				725: 'You do not have permission to modify records in the target table',
				726: 'There are more records in the import file than in the target table. Not all records were imported',
				727: 'There are more records in the target table than in the import file. Not all records were updated',
				729: 'Errors occurred during import. Records could not be imported',
				730: 'Unsupported Excel version. (Convert file to Excel 7.0 (Excel 95), Excel 97, 2000, or XP format and try again)',
				731: 'The file you are importing from contains no data',
				732: 'This file cannot be inserted because it contains other files',
				733: 'A table cannot be imported into itself',
				734: 'This file type cannot be displayed as a picture',
				735: 'This file type cannot be displayed as a picture. It will be inserted and displayed as a file',
				800: 'Unable to create file on disk',
				801: 'Unable to create temporary file on System disk',
				802: 'Unable to open file',
				803: 'File is single user or host cannot be found',
				804: 'File cannot be opened as read-only in its current state',
				805: 'File is damaged; use Recover command',
				806: 'File cannot be opened with this version of FileMaker Pro',
				807: 'File is not a FileMaker Pro file or is severely damaged',
				808: 'Cannot open file because access privileges are damaged',
				809: 'Disk/volume is full',
				810: 'Disk/volume is locked',
				811: 'Temporary file cannot be opened as FileMaker Pro file',
				813: 'Record Synchronization error on network',
				814: 'File(s) cannot be opened because maximum number is open',
				815: "Couldn't open lookup file",
				816: 'Unable to convert file',
				817: 'Unable to open file because it does not belong to this solution',
				819: 'Cannot save a local copy of a remote file',
				820: 'File is in the process of being closed',
				821: 'Host forced a disconnect',
				822: 'FMI files not found; reinstall missing files',
				823: 'Cannot set file to single-user, guests are connected',
				824: 'File is damaged or not a FileMaker file',
				900: 'General spelling engine error',
				901: 'Main spelling dictionary not installed',
				902: 'Could not launch the Help system',
				903: 'Command cannot be used in a shared file',
				904: 'Command can only be used in a file hosted under FileMaker Server',
				905: 'No active field selected; command can only be used if there is an active field',
				920: "Can't initialize the spelling engine",
				921: 'User dictionary cannot be loaded for editing',
				922: 'User dictionary cannot be found',
				923: 'User dictionary is read-only',
				951: 'An unexpected error occurred (*)',
				954: 'Unsupported XML grammar (*)',
				955: 'No database name (*)',
				956: 'Maximum number of database sessions exceeded (*)',
				957: 'Conflicting commands (*)',
				958: 'Parameter missing (*)',
				1200: 'Generic calculation error',
				1201: 'Too few parameters in the function',
				1202: 'Too many parameters in the function',
				1203: 'Unexpected end of calculation',
				1204: 'Number, text constant, field name or "(" expected',
				1205: 'Comment is not terminated with "*/"',
				1206: 'Text constant must end with a quotation mark',
				1207: 'Unbalanced parenthesis',
				1208: 'Operator missing, function not found or "(" not expected',
				1209: 'Name (such as field name or layout name) is missing',
				1210: 'Plug-in function has already been registered',
				1211: 'List usage is not allowed in this function',
				1212: 'An operator (for example, +, -, *) is expected here',
				1213: 'This variable has already been defined in the Let function',
				1214: 'AVERAGE, COUNT, EXTEND, GETREPETITION, MAX, MIN, NPV, STDEV, SUM and GETSUMMARY: expression found where a field alone is needed',
				1215: 'This parameter is an invalid Get function parameter',
				1216: 'Only Summary fields allowed as first argument in GETSUMMARY',
				1217: 'Break field is invalid',
				1218: 'Cannot evaluate the number',
				1219: 'A field cannot be used in its own formula',
				1220: 'Field type must be normal or calculated',
				1221: 'Data type must be number, date, time, or timestamp',
				1222: 'Calculation cannot be stored',
				1223: 'The function referred to does not exist',
				1400: 'ODBC driver initialization failed; make sure the ODBC drivers are properly installed',
				1401: 'Failed to allocate environment (ODBC)',
				1402: 'Failed to free environment (ODBC)',
				1403: 'Failed to disconnect (ODBC)',
				1404: 'Failed to allocate connection (ODBC)',
				1405: 'Failed to free connection (ODBC)',
				1406: 'Failed check for SQL API (ODBC)',
				1407: 'Failed to allocate statement (ODBC)',
				1408: 'Extended error (ODBC)',
			};
			return obj[code];
		}
	}
})();
